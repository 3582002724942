import { getCookie } from '@/utils/cookie';
import isBrowser from '@/utils/isBrowser';

import type { PayloadItems } from './types';

const category: Record<string, string> = {
  ['Oura Sizing Kit']: 'Sizing Kit',
  ['Charger Set']: 'Charger Set',
  ['Oura Membership']: 'Subscription',
  ['2-Year Protection Plan']: 'Warranty',
  ['3-Year Protection Plan']: 'Warranty',
};

interface ConsentCookie {
  custom?: {
    marketing?: Record<string, boolean>;
  };
}

export const productCategory = (title: PayloadItems['title']): string => {
  return category[title!] ?? 'Ring';
};

const parseConsentCookie = (consentCookie: string): ConsentCookie => {
  try {
    return JSON.parse(decodeURIComponent(consentCookie)) as ConsentCookie;
  } catch (e) {
    // Shouldn't happen, but if for some reason the cookie is malformed, we don't want to break tracking
    return {};
  }
};

const hasConsent = () => {
  const consentCookie = getCookie('tracking-preferences');
  // if there is no tracking-preferences cookie we have to assume we cannot client-side track
  if (!consentCookie) return false;
  const parsedCookie = parseConsentCookie(consentCookie);
  const marketingConsent = parsedCookie.custom?.marketing;
  return Boolean(marketingConsent);
};

export const canUseClientSideAnalytics = () => {
  if (!isBrowser()) return false;
  // Only allow client side analytics if tracking consent is given
  return hasConsent();
};
