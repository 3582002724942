import Script from 'next/script';

const NoibuScript = (): JSX.Element => {
  // eslint-disable-next-line no-process-env
  const env = process.env.NEXT_PUBLIC_OURA_ENV;
  const loadScript =
    env === 'sandbox' || env === 'staging' || env === 'production';

  if (loadScript) {
    return (
      <Script
        id="noibu-script"
        src="https://cdn.noibu.com/collect-core.js"
        strategy="beforeInteractive"
        onError={(e: Error) => {
          // eslint-disable-next-line no-console
          console.error('Script failed to load', e);
        }}
      />
    );
  }
  return <></>;
};
export default NoibuScript;
