'use client';

import type {
  AnalyticsSnippet,
  EventProperties,
  Options,
  UserTraits,
} from '@segment/analytics-next';
import type { ExtraContext } from '@segment/analytics-node/dist/types/app/types';

import { nextBackendClient } from '@/api/v2/api';
import { getCookie } from '@/utils/cookie';
import isBrowser from '@/utils/isBrowser';
import { readFromLocalStorage } from '@/utils/localStorage';

import type {
  BaseDataLayer,
  GA4PayloadType,
  PartialGA4ItemType,
  PayloadItems,
} from './types';
import { canUseClientSideAnalytics } from './utils';

interface DataLayer extends BaseDataLayer {
  ecommerce_data?: PartialGA4ItemType | GA4PayloadType;
  eventPath?: string | undefined;
  eventAttributes?: PayloadItems;
}

declare global {
  interface Window {
    analytics?: AnalyticsSnippet;
    dataLayer?: DataLayer[];
    ouraAnalytics: OuraAnalytics;
  }
}

export class OuraAnalytics {
  private serverBasePath: string;
  private statsigStableId: string | null;
  constructor({
    serverBasePath = '/api/analytics',
    statsigStableId = readFromLocalStorage('STATSIG_LOCAL_STORAGE_STABLE_ID'),
  }: {
    serverBasePath?: string;
    statsigStableId?: string | null;
  } = {}) {
    this.serverBasePath = serverBasePath;
    this.statsigStableId = statsigStableId;
  }

  async track(event: string, properties?: EventProperties, options?: Options) {
    const [type, trackFn] = this.getAnalyticsFn(
      window.analytics.track,
      this.serverTrack,
    );

    this.debugAnalyticsEvent(
      `Sending ${type} analytics track`,
      event,
      properties,
      options,
    );
    const enhancedProperties = this.enhanceProperties(properties);
    const enhancedContext = this.enhanceContext(options);
    await this.callAnalyticsFn('track', () =>
      trackFn(event, enhancedProperties, {
        ...options,
        context: enhancedContext,
      }),
    );
  }

  async page(
    name: string,
    properties?: EventProperties,
    context?: ExtraContext,
  ) {
    const [type, pageFn] = this.getAnalyticsFn(
      window.analytics.page,
      this.serverPage,
    );
    this.debugAnalyticsEvent(
      `Sending ${type} analytics page`,
      name,
      properties,
      context,
    );
    const enhancedProperties = this.enhanceProperties({ ...properties, name });
    const enhancedContext = this.enhanceContext(context);
    await this.callAnalyticsFn('page', () =>
      pageFn(name, enhancedProperties, enhancedContext),
    );
  }

  async identify(traits: UserTraits | string, context?: ExtraContext) {
    const [type, identifyFn] = this.getAnalyticsFn(
      window.analytics.identify,
      this.serverIdentify,
    );
    this.debugAnalyticsEvent(
      `Sending ${type} analytics identify`,
      traits,
      context,
    );
    const enhancedContext = this.enhanceContext(context);
    await this.callAnalyticsFn(
      'identify',
      () => identifyFn(traits, enhancedContext) as Promise<unknown>,
    );
  }

  gtmEvent(event: DataLayer) {
    window.dataLayer?.push(event);
  }

  private getAnalyticsFn = <T, S>(clientFn: T, serverFn: S) => {
    const canClientSideTrack = canUseClientSideAnalytics();
    const type = canClientSideTrack ? 'client' : 'server';
    const fn = canClientSideTrack ? clientFn : serverFn;
    return [type, fn] as const;
  };

  private enhanceProperties = (properties: EventProperties | undefined) => {
    return this.withFacebookPixelCookies(
      this.withStatisgIds(this.withPageInfo(properties)),
    );
  };

  private enhanceContext = (
    context: ExtraContext | undefined,
  ): ExtraContext => {
    const locale = getCookie('NEXT_LOCALE');
    return {
      page: this.withPageInfo(),
      locale: locale ?? 'en',
      ...context,
    };
  };

  private callAnalyticsFn = async (
    name: string,
    fn: () => Promise<unknown>,
  ) => {
    /**
     * Need to wrap fn because it can be non-Promise-returning function on initial load
     * (Segment queues up events before it's initialized and in that case it does not
     * return a promise).
     */
    return Promise.resolve(fn()).catch((e) => {
      // TODO - do we want to log these errors to datadog?
      this.debugAnalyticsEvent(`Error sending ${name} event`, e);
    });
  };

  private serverTrack = (
    event: string,
    properties?: EventProperties,
    options?: Options,
  ) => {
    return nextBackendClient.url(this.serverBasePath + '/track').post({
      event,
      properties,
      context: options?.context,
    });
  };

  private serverIdentify = (
    traits: UserTraits | string,
    context?: ExtraContext,
  ) => {
    return nextBackendClient.url(this.serverBasePath + '/identify').post({
      traits,
      context,
    });
  };

  private serverPage = (
    name: string,
    properties: EventProperties,
    context?: ExtraContext,
  ) => {
    return nextBackendClient.url(this.serverBasePath + '/page').post({
      name,
      properties,
      context,
    });
  };

  /*
   * This is needed to associate segment events to a statsig user
   */
  private withStatisgIds = (properties?: EventProperties) => {
    if (!this.statsigStableId) return properties;
    return {
      ...properties,
      statsigCustomIDs: ['stableID', this.statsigStableId],
      statsigStableId: this.statsigStableId,
    };
  };

  private withFacebookPixelCookies = (properties?: EventProperties) => {
    const fbcCookie = getCookie('_fbc');
    const fbpCookie = getCookie('_fbp');
    return {
      ...properties,
      ...(fbcCookie && { fbc: fbcCookie }),
      ...(fbpCookie && { fbp: fbpCookie }),
    };
  };

  private withPageInfo = (properties?: EventProperties) => {
    return {
      path: document.location.pathname,
      referrer: document.referrer,
      title: document.title,
      url: document.location.href,
      search: document.location.search,
      ...properties,
    };
  };

  private debugAnalyticsEvent(...args: unknown[]) {
    // eslint-disable-next-line no-process-env -- dev feature only
    if (process.env.DEBUG_ANALYTICS === 'true') {
      console.log(...args); // eslint-disable-line no-console
    }
  }
}

// initialize global analytics class in the browser
if (isBrowser()) {
  window.ouraAnalytics =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.ouraAnalytics ?? new OuraAnalytics();
}
